<template>
  <div class="clientCommon">
    <com-form
      ref="clientCommon"
      label-width="120px"
      label-position="right"
      :col="3"
      :configs="filterFormConfig"
      showType="info"
    ></com-form>
  </div>
</template>

<script>
import { formConfig } from "../config/formConfig.js"

export default {
  name: 'clientCommon',
  props:{
    injectData:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  watch:{
    'injectData._current'(val){
      if(val){
        this.filterFormConfig = formConfig(this).commmonFormConfig[0].tagConfigs
      }
    }
  },
  mounted() {
    this.$nextTick(()=>{
      this.tagFormConfig = formConfig(this).tagFormConfig
    })
  },
  data() {
    return {
      formData: {},
      filterFormConfig:[]
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>