export const tab_en = {
  "THead": "THead",
  "mqs_orderline_tab0": "mqs_orderline_tab0",
  "mqs_orderline_tab1": "mqs_orderline_tab1",
  "mqs_orderline_tab2": "mqs_orderline_tab2",
  "mqs_orderline_tab3": "mqs_orderline_tab3",
  "user_def_ol_tab0": "user_def_ol_tab0",
  "user_def_ol_tab1": "user_def_ol_tab1"
};

export const tab_zh = {
  "THead": "表头",
  "mqs_orderline_tab0": "综合",
  "mqs_orderline_tab1": "杂项",
  "mqs_orderline_tab2": "进口税和消费税",
  "mqs_orderline_tab3": "mqs_orderline_tab3",
  "user_def_ol_tab0": "用户自定义的_设置1",
  "user_def_ol_tab1": "用户自定义的_设置2",
}

