import {getFormConfig as getLineFormConfig} from './formDataConfig'
import {isCanShowBlock} from "../../../../../../utils";
import {tab_zh} from "../../../configs/lineFormTabTranslate";

export const formConfig = vm => {
  let arr = getLineFormConfig(vm).filterFormConfig
  let configArr = arr.filter(item => item.tabGroup && isCanShowBlock('dataInfo',item.showBlock));

  let thead = [], common = [];

  configArr.forEach(item => {
    if (!thead.includes(item.tabGroup)) {
      thead.push(item.tabGroup)
      item.disabled = true;
      item.placeholder = '';
      item.tabGroupName = tab_zh[item.tabGroup]
      common.push({label: item.tabGroupName, value: item.tabGroup, tagConfigs: [item]})
    } else {
      common.forEach(res => {
        if (res.value === item.tabGroup) {
          item.disabled = true;
          item.placeholder = '';
          res.tagConfigs.push(item)
          item.tabGroupName = tab_zh[item.tabGroup]
        }
      })
    }
  })


  return {
    commmonFormConfig: common.filter(item => item.value === 'THead'),
    tagFormConfig: common.filter(item => item.value !== 'THead'),
    thead
  }
};
