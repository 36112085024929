<template>
  <div class="clientTag">
    <el-tabs type="border-card"  v-model="tagName">
      <el-tab-pane v-for="(tagForm, index) in tagFormConfig" :key="'tagForm' + index" :label="tagForm.label">
        <com-form
          :ref="tagForm.value"
          label-width="160px"
          label-position="right"
          :col="3"
          :configs="tagForm.tagConfigs"
          showType="info"
        ></com-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { formConfig } from "../config/formConfig.js"
export default {
  name: 'clientTag',
  props:{
    injectData:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  watch:{
    'injectData._current'(val){
      if(val){
        this.tagFormConfig = formConfig(this).tagFormConfig
      }
    }
  },
  mounted() {
    this.$nextTick(()=>{
      this.tagFormConfig = formConfig(this).tagFormConfig
    })
  },
  data() {
    return {
      tagName: '',
      formData: {},
      tagFormConfig: []
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>